.App {
  text-align: center;
}

.real-estate-calc__wrapper {
  text-align: center;
}

.adjustment-form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.adjustment-form > div {
  margin: 12px;
}

.edit-table {
  width: 100%;
}

table tr th:not(:first-child) {
  min-width: 200px;
}
table tr td>div {
  margin: auto;
}

table tr th>span{
  display: flex !important;
  align-items: center !important;;
  justify-content: center!important;
}
